import React, { createContext, useState, useEffect } from 'react';
import fetch from 'isomorphic-fetch';
import Client from 'shopify-buy/index.unoptimized.umd';

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_SUBDOMAIN,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_KEY,
  },
  fetch
);

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItems: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
    setLoading(false);
  };

  useEffect(() => {
    const initializeCheckout = async () => {
      setLoading(true);

      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          );
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            setLoading(false);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
      setLoading(false);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId, quantity, inventory) => {
    setLoading(true);

    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity:
          inventory === false || inventory === null || inventory === undefined
            ? parseInt(quantity, 10) // No inventory tracking, use the provided quantity
            : Math.min(parseInt(quantity, 10), parseInt(inventory, 10)), // Respect inventory limits
      },
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
      });
  };

  const removeLineItems = (checkoutID, lineItemIDs) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [...lineItemIDs])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity, inventory) => {
    setLoading(true);

    const lineItemsToUpdate = [
      {
        id: lineItemID,
        quantity:
          inventory === false || inventory === null || inventory === undefined
            ? parseInt(quantity, 10) // No inventory tracking, use the provided quantity
            : Math.min(parseInt(quantity, 10), parseInt(inventory, 10)), // Respect inventory limits
      },
    ];

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItems,
        updateLineItem,
        setDidJustAddToCart,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
